// const Paragraphs = [
//   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna. Adipiscing commodo elit at imperdiet dui. Duis tristique sollicitudin nibh sit amet commodo nulla. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Elit eget gravida cum sociis natoque penatibus. Risus viverra adipiscing at in tellus integer feugiat scelerisque varius. Id ornare arcu odio ut sem nulla pharetra diam sit. Tincidunt augue interdum velit euismod in pellentesque massa placerat duis. Facilisis sed odio morbi quis commodo odio. Porttitor rhoncus dolor purus non enim praesent elementum. Etiam erat velit scelerisque in dictum non consectetur. At augue eget arcu dictum varius duis at consectetur lorem.",
//   "Vel eros donec ac odio tempor orci dapibus ultrices in. Mi bibendum neque egestas congue quisque egestas diam in arcu. Risus quis varius quam quisque id diam vel quam. Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus. In arcu cursus euismod quis viverra nibh cras. Aliquet nec ullamcorper sit amet risus. Malesuada fames ac turpis egestas integer eget aliquet. Cras semper auctor neque vitae. Dictum varius duis at consectetur lorem. Neque egestas congue quisque egestas diam in. Maecenas accumsan lacus vel facilisis. Duis at consectetur lorem donec massa. Diam vulputate ut pharetra sit amet aliquam id diam. Adipiscing elit ut aliquam purus sit. Lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant.",
//   "In aliquam sem fringilla ut morbi. Nec tincidunt praesent semper feugiat nibh sed. Euismod lacinia at quis risus sed vulputate odio ut. Ullamcorper a lacus vestibulum sed arcu non odio euismod. Amet venenatis urna cursus eget. Etiam non quam lacus suspendisse faucibus. Pharetra et ultrices neque ornare aenean. Amet est placerat in egestas erat imperdiet sed. Amet venenatis urna cursus eget nunc scelerisque viverra. Etiam sit amet nisl purus in mollis.",
//   "Pellentesque sit amet porttitor eget dolor morbi. Quam nulla porttitor massa id neque aliquam vestibulum. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Ipsum dolor sit amet consectetur adipiscing elit duis. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Malesuada fames ac turpis egestas. Sit amet luctus venenatis lectus magna fringilla urna porttitor. Ipsum consequat nisl vel pretium. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Tempor nec feugiat nisl pretium fusce. Rhoncus mattis rhoncus urna neque. Leo vel fringilla est ullamcorper eget. Scelerisque in dictum non consectetur a erat nam at. Convallis posuere morbi leo urna. Commodo ullamcorper a lacus vestibulum.",
//   "Ornare aenean euismod elementum nisi quis eleifend quam. In arcu cursus euismod quis viverra nibh. Velit dignissim sodales ut eu. Placerat duis ultricies lacus sed turpis. Mauris a diam maecenas sed enim ut sem viverra. Felis donec et odio pellentesque. Purus in mollis nunc sed id semper risus in hendrerit. Tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Sed sed risus pretium quam vulputate dignissim suspendisse. Volutpat maecenas volutpat blandit aliquam etiam. Enim nunc faucibus a pellentesque sit amet."
// ]

// export default function Placeholder(length = 1) {
//   return Paragraphs.slice(0, length).join("\n\n");
// }

export default function Placeholder() {
  return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
}